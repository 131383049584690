import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';

function Login() {
    const [email, setEmail] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const navigate = useNavigate();
    const { setUser } = useUser();

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Attempting to login with:', email);
    
        const BACKEND_API_URL = 'https://mirofitnessbackend.onrender.com/request-magic-link/';
    
        try {
            const response = await fetch(BACKEND_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Magic link sent:', result);
                setLoginStatus('Magic link sent. Please check your inbox.');
                window.alert(`Magic link sent. Please check your inbox at ${email}`);
            } else {
                console.error('Login failed:', response.status);
                setLoginStatus(result.message || 'Failed to send magic link');
                window.alert(result.message || 'Failed to send magic link');
            }
        } catch (error) {
            console.error('Error processing login:', error);
            setLoginStatus('Error processing login');
            window.alert('Error processing login');
        }
    };

    return (
        <Container>
            <LoginBox>
                <Title>Login</Title>
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Button type="submit">
                        Login
                    </Button>
                </Form>
                {loginStatus && <StatusMessage>{loginStatus}</StatusMessage>}
                <RegisterPrompt>
                    Don't Have an Account?
                    <br />
                    <RegisterLink to="/Register">
                        Register here
                    </RegisterLink>
                </RegisterPrompt>
            </LoginBox>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
`;

const LoginBox = styled.div`
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
`;

const Title = styled.h1`
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    padding: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    &:focus {
        outline: none;
        border-color: #6e8efb;
    }
`;

const Button = styled.button`
    padding: 15px;
    font-size: 16px;
    background-color: #6e8efb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: #5c7cfa;
    }
`;

const StatusMessage = styled.div`
    color: #e74c3c;
    text-align: center;
    margin-top: 1rem;
`;

const RegisterPrompt = styled.div`
    text-align: center;
    margin-top: 20px;
`;

const RegisterLink = styled(Link)`
    color: #6e8efb;
    font-size: 18px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

export default Login;