import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaDumbbell, FaSearch, FaChevronDown, FaPlus } from 'react-icons/fa';

const ExerciseDB = () => {
  const [exercises, setExercises] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [targets, setTargets] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('bodyParts');
  const [selectedItem, setSelectedItem] = useState(null);
  const [limit, setLimit] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedWorkout, setSelectedWorkout] = useState('');
  const [newWorkoutName, setNewWorkoutName] = useState('');
  const [success, setSuccess] = useState('');

  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL || '';

  const fetchExercises = async (endpoint, params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}${endpoint}`, { params });
      return response.data;
    } catch (err) {
      console.error('Error fetching exercises:', err);
      setError(`Error fetching data: ${err.message}`);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/workouts`);
      setWorkouts(response.data || []);
    } catch (err) {
      console.error('Error fetching workouts:', err);
      setError(`Error fetching workouts: ${err.message}`);
      setWorkouts([]);
    }
  };

  

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [bodyPartsData, equipmentData, targetsData] = await Promise.all([
          fetchExercises('/exercises/bodyPartList'),
          fetchExercises('/exercises/equipmentList'),
          fetchExercises('/exercises/targetList')
        ]);

        setBodyParts(bodyPartsData || []);
        setEquipment(equipmentData || []);
        setTargets(targetsData || []);

        await fetchWorkouts();
      } catch (err) {
        console.error('Error loading initial data:', err);
        setError(`Error loading initial data: ${err.message}`);
      }
    };

    loadInitialData();
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedItem(null);
    setExercises([]);
  };

  const handleItemSelect = async (item) => {
    setSelectedItem(item);
    let endpoint;
    switch (selectedCategory) {
      case 'bodyParts':
        endpoint = `/exercises/bodyPart/${item}`;
        break;
      case 'equipment':
        endpoint = `/exercises/equipment/${item}`;
        break;
      case 'targets':
        endpoint = `/exercises/target/${item}`;
        break;
      default:
        return;
    }
    const data = await fetchExercises(endpoint, { limit });
    setExercises(data || []);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    if (selectedItem) {
      handleItemSelect(selectedItem);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const addExerciseToWorkout = async (exercise) => {
    if (!selectedWorkout) {
      setError('Please select a workout.');
      return;
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/workouts/${selectedWorkout}/exercises`, {
        name: exercise.name,
        sets: 3,
        reps: 10,
        weight: 0,
      });
  
      if (response.status === 200) {
        setSuccess(`${exercise.name} added to workout successfully!`);
        setTimeout(() => setSuccess(null), 3000); // Hide the success message after 3 seconds
        // Refresh the workouts list
        await fetchWorkouts();
      } else {
        setError('Failed to add exercise to workout. Please try again.');
      }
    } catch (err) {
      console.error('Error adding exercise to workout:', err);
      setError(`An error occurred while adding the exercise to the workout: ${err.message}`);
    }
  };

  const createWorkout = async () => {
    if (!newWorkoutName) {
      setError('Please provide a workout name.');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/workouts`, {
        name: newWorkoutName,
        description: '',
        duration: 0,
        type: 'strength',
        exercises: []
      });

      if (response.status === 201) {
        setSuccess('Workout created successfully!');
        setNewWorkoutName('');
        await fetchWorkouts();
      } else {
        setError('Failed to create workout. Please try again.');
      }
    } catch (err) {
      console.error('Error creating workout:', err);
      setError(`An error occurred while creating the workout: ${err.message}`);
    }
  };

  const getCategoryItems = () => {
    switch (selectedCategory) {
      case 'bodyParts':
        return bodyParts;
      case 'equipment':
        return equipment;
      case 'targets':
        return targets;
      default:
        return [];
    }
  };

  const filteredExercises = exercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Header>
        <Title>Exercise Database</Title>
        <Subtitle>Explore exercises and add them to your workouts</Subtitle>
      </Header>
      <WorkoutControls>
        <WorkoutSelector>
          <label htmlFor="workout-select">Select Workout: </label>
          <select
            id="workout-select"
            value={selectedWorkout}
            onChange={(e) => setSelectedWorkout(e.target.value)}
          >
            <option value="">Select a workout</option>
            {workouts.map((workout) => (
              <option key={workout.id} value={workout.id}>
                {workout.name}
              </option>
            ))}
          </select>
        </WorkoutSelector>
        <NewWorkout>
          <input
            type="text"
            placeholder="New workout name"
            value={newWorkoutName}
            onChange={(e) => setNewWorkoutName(e.target.value)}
          />
          <Button onClick={createWorkout}>Create Workout</Button>
        </NewWorkout>
      </WorkoutControls>
      <ControlsWrapper>
        <CategorySelector>
          {['bodyParts', 'equipment', 'targets'].map((category) => (
            <CategoryButton
              key={category}
              onClick={() => handleCategorySelect(category)}
              selected={selectedCategory === category}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </CategoryButton>
          ))}
        </CategorySelector>
        <SearchBar>
          <FaSearch />
          <input
            type="text"
            placeholder="Search exercises..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchBar>
        <LimitSelector>
          <label htmlFor="limit">Show: </label>
          <select id="limit" value={limit} onChange={handleLimitChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <FaChevronDown />
        </LimitSelector>
      </ControlsWrapper>
      <ContentWrapper>
        <Sidebar>
          <SidebarTitle>{selectedCategory}</SidebarTitle>
          <ItemList>
            <AnimatePresence>
              {getCategoryItems().map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <Item
                    onClick={() => handleItemSelect(item)}
                    selected={selectedItem === item}
                  >
                    {item}
                  </Item>
                </motion.li>
              ))}
            </AnimatePresence>
          </ItemList>
        </Sidebar>
        <ExerciseList>
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : (
            <AnimatePresence>
              {filteredExercises.map((exercise, index) => (
                <ExerciseCard
                  key={exercise.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <ExerciseImage src={exercise.gifUrl} alt={exercise.name} />
                  <ExerciseDetails>
                    <ExerciseName>{exercise.name}</ExerciseName>
                    <ExerciseInfo>
                      <InfoItem>
                        <strong>Target:</strong> {exercise.target}
                      </InfoItem>
                      <InfoItem>
                        <strong>Equipment:</strong> {exercise.equipment}
                      </InfoItem>
                    </ExerciseInfo>
                    <AddButton onClick={() => addExerciseToWorkout(exercise)}>
                      <FaPlus /> Add to Workout
                    </AddButton>
                  </ExerciseDetails>
                </ExerciseCard>
              ))}
            </AnimatePresence>
          )}
        </ExerciseList>
      </ContentWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
    </Container>
  );
};

// Styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const WorkoutControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const WorkoutSelector = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 0.5rem;
  }

  select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

const NewWorkout = styled.div`
  display: flex;
  align-items: center;

  input {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 0.5rem;
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const CategorySelector = styled.div`
  display: flex;
  gap: 1rem;
`;

const CategoryButton = styled.button`
  background-color: ${props => props.selected ? '#3498db' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  border: 1px solid #3498db;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3498db;
    color: white;
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;

  input {
    border: none;
    outline: none;
    margin-left: 0.5rem;
  }
`;

const LimitSelector = styled.div`
  display: flex;
  align-items: center;

  select {
    margin: 0 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  height: fit-content;
`;

const SidebarTitle = styled.h2`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const ItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
`;

const Item = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: ${props => props.selected ? '#3498db' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: ${props => props.selected ? 'bold' : 'normal'};

  &:hover {
    background-color: ${props => props.selected ? '#2980b9' : '#e0e0e0'};
    transform: translateY(-2px);
  }
`;

const ExerciseList = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
`;

const ExerciseCard = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ExerciseImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ExerciseDetails = styled.div`
  padding: 1rem;
`;

const ExerciseName = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
`;

const ExerciseInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const InfoItem = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin: 0;

  strong {
    color: #3498db;
  }
`;

const AddButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0.5rem;

  &:hover {
    background-color: #2980b9;
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #3498db;
  font-size: 1.2rem;
  gap: 1rem;
`;

const ErrorMessage = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #ff4d4d;
  background-color: #ffe6e6;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

const SuccessMessage = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #28a745;
  background-color: #e6ffe6;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

export default ExerciseDB;