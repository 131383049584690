import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUtensils, FaWeight, FaFire, FaAllergies, FaCalendarAlt } from 'react-icons/fa';

const DietPlanner = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

  const [dietPlan, setDietPlan] = useState({
    dietry_prefs: "Vegan",
    goal: "Weight loss",
    caloric_intake: 2000,
    proteins: 200,
    fats: 50,
    carbohydrates: 500,
    allergies_or_restrictions: "",
    plan_type: "Daily",
    meals_frequency: 4
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDietPlan(prev => ({ ...prev, [name]: name === 'meals_frequency' ? parseInt(value) : value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);
  
    try {
      const dietPlanToSubmit = { ...dietPlan };
      if (!dietPlanToSubmit.allergies_or_restrictions.trim()) {
        dietPlanToSubmit.allergies_or_restrictions = "None";
      }
  
      const response = await axios.post(`${API_BASE_URL}/dietplanner/generate`, dietPlanToSubmit);
      setResult(response.data.mealPlan);
    } catch (err) {
      setError(err.response?.data?.detail || err.message || 'An error occurred');
      console.error('API Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderMeal = (meal) => (
    <MealCard key={meal.Recipe}>
      <MealTitle>{meal.Recipe}</MealTitle>
      {meal.Time && <MealTime><FaCalendarAlt /> {meal.Time}</MealTime>}
      <MealInfo><strong>Ingredients:</strong> {meal.Ingredients.join(', ')}</MealInfo>
      <NutritionInfo>
        <NutritionItem><FaFire /> {meal.Calories} cal</NutritionItem>
        <NutritionItem><FaUtensils /> {meal.Protein}g protein</NutritionItem>
        <NutritionItem><FaUtensils /> {meal.Carbohydrates}g carbs</NutritionItem>
        <NutritionItem><FaUtensils /> {meal.Fats}g fats</NutritionItem>
      </NutritionInfo>
    </MealCard>
  );

  const renderDailyPlan = (meals) => (
    <PlanContainer>
      <PlanTitle>Your Daily Diet Plan</PlanTitle>
      {meals.map(renderMeal)}
    </PlanContainer>
  );

  const renderWeeklyPlan = (weekPlan) => (
    <PlanContainer>
      <PlanTitle>Your Weekly Diet Plan</PlanTitle>
      {Object.entries(weekPlan).map(([day, meals]) => (
        <DayCard key={day}>
          <DayTitle>{day}</DayTitle>
          {meals.map(renderMeal)}
        </DayCard>
      ))}
    </PlanContainer>
  );

  
  return (
    <Container>
      <Title>Diet Planner</Title>
      <Subtitle>Personalized nutrition plans for your health goals</Subtitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="dietry_prefs">
            <FaUtensils /> Dietary Preference
          </Label>
          <Select
            id="dietry_prefs"
            name="dietry_prefs"
            value={dietPlan.dietry_prefs}
            onChange={handleInputChange}
          >
            <option value="Vegan">Vegan</option>
            <option value="Vegetarian">Vegetarian</option>
            <option value="Pescatarian">Pescatarian</option>
            <option value="Omnivore">Omnivore</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goal">
            <FaWeight /> Goal
          </Label>
          <Select
            id="goal"
            name="goal"
            value={dietPlan.goal}
            onChange={handleInputChange}
          >
            <option value="Weight loss">Weight loss</option>
            <option value="Weight gain">Weight gain</option>
            <option value="Maintenance">Maintenance</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="caloric_intake">
            <FaFire /> Caloric Intake
          </Label>
          <Input
            id="caloric_intake"
            type="number"
            name="caloric_intake"
            value={dietPlan.caloric_intake}
            onChange={handleInputChange}
            placeholder="e.g., 2000"
          />
        </FormGroup>
        <NutritionInputs>
          <FormGroup>
            <Label htmlFor="proteins">Proteins (g)</Label>
            <Input
              id="proteins"
              type="number"
              name="proteins"
              value={dietPlan.proteins}
              onChange={handleInputChange}
              placeholder="e.g., 150"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="fats">Fats (g)</Label>
            <Input
              id="fats"
              type="number"
              name="fats"
              value={dietPlan.fats}
              onChange={handleInputChange}
              placeholder="e.g., 65"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="carbohydrates">Carbs (g)</Label>
            <Input
              id="carbohydrates"
              type="number"
              name="carbohydrates"
              value={dietPlan.carbohydrates}
              onChange={handleInputChange}
              placeholder="e.g., 250"
            />
          </FormGroup>
        </NutritionInputs>
        <FormGroup>
          <Label htmlFor="allergies_or_restrictions">
            <FaAllergies /> Allergies or Restrictions
          </Label>
          <Input
            id="allergies_or_restrictions"
            type="text"
            name="allergies_or_restrictions"
            value={dietPlan.allergies_or_restrictions}
            onChange={handleInputChange}
            placeholder="e.g., Nuts, Dairy (or leave empty for none)"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="plan_type">Plan Type</Label>
          <Select
            id="plan_type"
            name="plan_type"
            value={dietPlan.plan_type}
            onChange={handleInputChange}
          >
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="meals_frequency">Meals per Day</Label>
          <Input
            id="meals_frequency"
            type="number"
            name="meals_frequency"
            value={dietPlan.meals_frequency}
            onChange={handleInputChange}
            placeholder="e.g., 3"
          />
        </FormGroup>
        <Button type="submit">Generate Diet Plan</Button>
      </Form>
      <AnimatePresence>
        {loading && (
          <LoadingSpinner
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            Crafting your personalized diet plan...
          </LoadingSpinner>
        )}
        {error && (
          <ErrorMessage
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            {error}
          </ErrorMessage>
        )}
        {result && (
          <ResultContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            {Array.isArray(result.meals) 
              ? renderDailyPlan(result.meals)
              : renderWeeklyPlan(result)}
          </ResultContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: 700;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
  margin-bottom: 2rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #3498db;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  background-color: white;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const NutritionInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

const Button = styled.button`
  padding: 1rem;
  font-size: 1.1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

const LoadingSpinner = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.2rem;
  color: #3498db;
`;

const ErrorMessage = styled(motion.div)`
  color: #e74c3c;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fadbd8;
  border-radius: 5px;
`;

const ResultContainer = styled(motion.div)`
  margin-top: 2rem;
`;

const PlanContainer = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
`;

const PlanTitle = styled.h2`
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
`;

const MealCard = styled.div`
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const MealTitle = styled.h3`
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const MealTime = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MealInfo = styled.p`
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 1rem;
  line-height: 1.5;
`;

const NutritionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`;

const NutritionItem = styled.span`
  font-size: 0.9rem;
  color: #7f8c8d;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #3498db;
  }
`;

const DayCard = styled.div`
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
`;

const DayTitle = styled.h3`
  font-size: 1.6rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export default DietPlanner;