import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Register() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // If isSubmitting is true, immediately return
        if (isSubmitting) return;
    
        setIsSubmitting(true); // Disable the button
    
        console.log('Attempting to register with:', email);
    
        const BACKEND_API_URL = 'https://mirofitnessbackend.onrender.com/register';
    
        try {
            const response = await fetch(BACKEND_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, username }),
            });
    
            if (!response.ok) {
                console.error('Registration failed:', response.status);
                window.alert('Registration failed');
                setIsSubmitting(false);
                return;
            }
    
            const result = await response.json();
            console.log('Registration successful:', result);
            window.alert(`Registration successful. Please check your inbox at ${email}. If you don't see the email, please check your spam folder.`);
        } catch (error) {
            console.error('Error processing registration:', error);
            window.alert('Error processing registration');
            setIsSubmitting(false);
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f8f8f8', // A light background color for better contrast
            color: '#333', // Darker text for better readability
            fontFamily: 'Arial, sans-serif', // A standard, legible font
        }}>
            <div style={{ maxWidth: '400px', textAlign: 'center', marginBottom: '30px' }}>
                <h1 style={{ fontSize: '24px', margin: '0 0 10px 0' }}> Sign Up For Free To Discover</h1>
                <p style={{ fontSize: '18px', margin: '0' }}>2M+ Creators across Instagram, TikTok and YouTube.</p>
            </div>
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px', padding: '0 20px', boxSizing: 'border-box' }}>
                <input
                    type="text"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{
                        padding: '15px',
                        marginBottom: '15px',
                        fontSize: '16px',
                        width: '100%',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        border: '1px solid #ccc'
                    }}
                    required
                />
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                        padding: '15px',
                        marginBottom: '15px',
                        fontSize: '16px',
                        width: '100%',
                        boxSizing: 'border-box',
                        borderRadius: '5px',
                        border: '1px solid #ccc'
                    }}
                    required
                />
                <button
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                        padding: '15px',
                        fontSize: '16px',
                        backgroundColor: '#000000', // A more standard blue color
                        color: 'white',
                        cursor: isSubmitting ? 'default' : 'pointer',
                        width: '100%',
                        border: 'none',
                        borderRadius: '5px',
                        transition: 'background-color 0.3s',
                        marginBottom: '10px'
                    }}
                >
                    {isSubmitting ? 'Registering...' : 'Register'}
                </button>
            </form>
            {registrationStatus && <div style={{ marginTop: '10px', textAlign: 'center', color: 'red' }}>{registrationStatus}</div>}
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                Already have an account?
                <Link to="/Signin" style={{ marginLeft: '5px', color: '#007BFF', textDecoration: 'none' }}>Login here</Link>
            </div>
        </div>
    );
}

export default Register;
