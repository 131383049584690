import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaDumbbell, FaAppleAlt, FaClipboardList, FaSpinner } from 'react-icons/fa';

const WorkoutPlanner = () => {
  const [activeTab, setActiveTab] = useState('exerciseDetails');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const [exerciseDetails, setExerciseDetails] = useState({
    exercise_name: '',
    lang: 'en',
  });

  const [nutritionAdvice, setNutritionAdvice] = useState({
    goal: 'muscle_gain',
    dietary_restrictions: [],
    current_weight: 0,
    target_weight: 0,
    daily_activity_level: 'moderate',
    lang: 'en',
  });

  const [workoutPlan, setWorkoutPlan] = useState({
    goal: '',
    fitness_level: '',
    preferences: {
      exercise_types: [],
      equipment_available: [],
    },
    health_conditions: [],
    schedule: {
      days_per_week: 4,
      session_duration: 45,
    },
    lang: 'en',
    plan_duration_weeks: 4,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      let response;
      switch (activeTab) {
        case 'exerciseDetails':
          response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/exerciseDetails`, exerciseDetails);
          break;
        case 'nutritionAdvice':
          response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/nutritionAdvice`, nutritionAdvice);
          break;
        case 'generateWorkoutPlan':
          response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/generateWorkoutPlan`, workoutPlan);
          break;
        default:
          throw new Error('Invalid tab');
      }
      setResult(response.data.result);
    } catch (err) {
      setError(err.response?.data?.detail || err.message || 'An error occurred');
      console.error('API Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, stateSetter) => {
    const { name, value } = e.target;
    stateSetter(prev => {
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        return { ...prev, [parent]: { ...prev[parent], [child]: value } };
      }
      return { ...prev, [name]: value };
    });
  };

  const handleArrayInputChange = (e, stateSetter, field) => {
    const { value } = e.target;
    stateSetter(prev => {
      const [parent, child] = field.split('.');
      const newArray = value.split(',').map(item => item.trim());
      return { 
        ...prev, 
        [parent]: { 
          ...prev[parent], 
          [child]: newArray 
        } 
      };
    });
  };


  const renderForm = () => {
    switch (activeTab) {
      case 'exerciseDetails':
        return (
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Label htmlFor="exercise_name">Exercise Name</Label>
              <Input
                id="exercise_name"
                type="text"
                name="exercise_name"
                value={exerciseDetails.exercise_name}
                onChange={(e) => handleInputChange(e, setExerciseDetails)}
                placeholder="e.g., Squat, Bench Press"
                required
              />
            </InputGroup>
            <Button type="submit">Get Exercise Details</Button>
          </Form>
        );
      case 'nutritionAdvice':
        return (
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Label htmlFor="goal">Goal</Label>
              <Select
                id="goal"
                name="goal"
                value={nutritionAdvice.goal}
                onChange={(e) => handleInputChange(e, setNutritionAdvice)}
              >
                <option value="muscle_gain">Muscle Gain</option>
                <option value="weight_loss">Weight Loss</option>
                <option value="maintenance">Maintenance</option>
              </Select>
            </InputGroup>
            <InputGroup>
              <Label htmlFor="dietary_restrictions">Dietary Restrictions</Label>
              <Input
                id="dietary_restrictions"
                type="text"
                name="dietary_restrictions"
                value={nutritionAdvice.dietary_restrictions.join(', ')}
                onChange={(e) => handleArrayInputChange(e, setNutritionAdvice, 'dietary_restrictions')}
                placeholder="e.g., vegan, gluten-free"
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="current_weight">Current Weight (kg)</Label>
              <Input
                id="current_weight"
                type="number"
                name="current_weight"
                value={nutritionAdvice.current_weight}
                onChange={(e) => handleInputChange(e, setNutritionAdvice)}
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="target_weight">Target Weight (kg)</Label>
              <Input
                id="target_weight"
                type="number"
                name="target_weight"
                value={nutritionAdvice.target_weight}
                onChange={(e) => handleInputChange(e, setNutritionAdvice)}
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="daily_activity_level">Daily Activity Level</Label>
              <Select
                id="daily_activity_level"
                name="daily_activity_level"
                value={nutritionAdvice.daily_activity_level}
                onChange={(e) => handleInputChange(e, setNutritionAdvice)}
              >
                <option value="sedentary">Sedentary</option>
                <option value="light">Light</option>
                <option value="moderate">Moderate</option>
                <option value="active">Active</option>
                <option value="very_active">Very Active</option>
              </Select>
            </InputGroup>
            <Button type="submit">Get Nutrition Advice</Button>
          </Form>
        );
      case 'generateWorkoutPlan':
        return (
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Label htmlFor="goal">Goal</Label>
              <Select
                id="goal"
                name="goal"
                value={workoutPlan.goal}
                onChange={(e) => handleInputChange(e, setWorkoutPlan)}
              >
                <option value="">Select a goal</option>
                <option value="weight_loss">Weight Loss</option>
                <option value="muscle_gain">Muscle Gain</option>
                <option value="endurance">Endurance</option>
                <option value="strength">Strength</option>
              </Select>
            </InputGroup>
            <InputGroup>
              <Label htmlFor="fitness_level">Fitness Level</Label>
              <Select
                id="fitness_level"
                name="fitness_level"
                value={workoutPlan.fitness_level}
                onChange={(e) => handleInputChange(e, setWorkoutPlan)}
              >
                <option value="">Select your fitness level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </Select>
            </InputGroup>
            <InputGroup>
              <Label htmlFor="exercise_types">Exercise Types</Label>
              <Input
                id="exercise_types"
                type="text"
                name="preferences.exercise_types"
                value={workoutPlan.preferences.exercise_types.join(', ')}
                onChange={(e) => handleArrayInputChange(e, setWorkoutPlan, 'preferences.exercise_types')}
                placeholder="e.g., cardio, strength, yoga"
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="equipment_available">Available Equipment</Label>
              <Input
                id="equipment_available"
                type="text"
                name="preferences.equipment_available"
                value={workoutPlan.preferences.equipment_available.join(', ')}
                onChange={(e) => handleArrayInputChange(e, setWorkoutPlan, 'preferences.equipment_available')}
                placeholder="e.g., dumbbells, yoga mat, resistance bands"
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="health_conditions">Health Conditions</Label>
              <Input
                id="health_conditions"
                type="text"
                name="health_conditions"
                value={workoutPlan.health_conditions.join(', ')}
                onChange={(e) => handleArrayInputChange(e, setWorkoutPlan, 'health_conditions')}
                placeholder="e.g., back pain, asthma"
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="days_per_week">Days per Week</Label>
              <Input
                id="days_per_week"
                type="number"
                name="days_per_week"
                value={workoutPlan.schedule.days_per_week}
                onChange={(e) => setWorkoutPlan(prev => ({ ...prev, schedule: { ...prev.schedule, days_per_week: parseInt(e.target.value) } }))}
                min="1"
                max="7"
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="session_duration">Session Duration (minutes)</Label>
              <Input
                id="session_duration"
                type="number"
                name="session_duration"
                value={workoutPlan.schedule.session_duration}
                onChange={(e) => setWorkoutPlan(prev => ({ ...prev, schedule: { ...prev.schedule, session_duration: parseInt(e.target.value) } }))}
                min="15"
                max="120"
                step="5"
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="plan_duration_weeks">Plan Duration (weeks)</Label>
              <Input
                id="plan_duration_weeks"
                type="number"
                name="plan_duration_weeks"
                value={workoutPlan.plan_duration_weeks}
                onChange={(e) => handleInputChange(e, setWorkoutPlan)}
                min="1"
                max="12"
                required
              />
            </InputGroup>
            <Button type="submit">Generate Workout Plan</Button>
          </Form>
        );
      default:
        return null;
    }
  };

  const renderResult = () => {
    if (!result) return null;

    switch (activeTab) {
      case 'exerciseDetails':
        return (
          <ResultContainer>
            <ResultTitle>{result.exercise_name}</ResultTitle>
            <ResultSection>
              <ResultLabel>Description:</ResultLabel>
              <ResultText>{result.description}</ResultText>
            </ResultSection>
            <ResultSection>
              <ResultLabel>Primary Muscles:</ResultLabel>
              <ResultText>{result.primary_muscles?.join(', ') || 'N/A'}</ResultText>
            </ResultSection>
            <ResultSection>
              <ResultLabel>Secondary Muscles:</ResultLabel>
              <ResultText>{result.secondary_muscles?.join(', ') || 'N/A'}</ResultText>
            </ResultSection>
            <ResultSection>
              <ResultLabel>Equipment Needed:</ResultLabel>
              <ResultText>{result.equipment_needed?.join(', ') || 'N/A'}</ResultText>
            </ResultSection>
            <ResultSection>
              <ResultLabel>Instructions:</ResultLabel>
              {result.instructions ? (
                <ResultList>
                  {result.instructions.map((instruction, index) => (
                    <ResultListItem key={index}>{instruction}</ResultListItem>
                  ))}
                </ResultList>
              ) : (
                <ResultText>No instructions available.</ResultText>
              )}
            </ResultSection>
          </ResultContainer>
        );
      case 'nutritionAdvice':
        return (
          <ResultContainer>
            <ResultTitle>Nutrition Advice</ResultTitle>
            <ResultSection>
              <ResultLabel>Daily Calorie Intake:</ResultLabel>
              <ResultText>{result.daily_calorie_intake} calories</ResultText>
            </ResultSection>
            <ResultSection>
              <ResultLabel>Macronutrient Breakdown:</ResultLabel>
              <ResultList>
                <ResultListItem>Protein: {result.macronutrient_breakdown.protein}g</ResultListItem>
                <ResultListItem>Carbohydrates: {result.macronutrient_breakdown.carbohydrates}g</ResultListItem>
                <ResultListItem>Fat: {result.macronutrient_breakdown.fat}g</ResultListItem>
              </ResultList>
            </ResultSection>
            <ResultSection>
              <ResultLabel>Meal Suggestions:</ResultLabel>
              {result.meal_suggestions.map((meal, index) => (
                <MealSuggestion key={index}>
                  <MealTitle>{meal.meal_type}</MealTitle>
                  {meal.suggestions.map((suggestion, idx) => (
                    <SuggestionItem key={idx}>
                      <SuggestionName>{suggestion.name}</SuggestionName>
                      <SuggestionCalories>{suggestion.calories} calories</SuggestionCalories>
                      <SuggestionIngredients>
                        Ingredients: {suggestion.ingredients.join(', ')}
                      </SuggestionIngredients>
                    </SuggestionItem>
                  ))}
                </MealSuggestion>
              ))}
            </ResultSection>
          </ResultContainer>
        );
      case 'generateWorkoutPlan':
        return (
          <ResultContainer>
            <ResultTitle>Your Personalized Workout Plan</ResultTitle>
            {result.workout_plan.map((day, index) => (
              <WorkoutDay key={index}>
                <WorkoutDayTitle>Day {index + 1}</WorkoutDayTitle>
                {day.exercises.map((exercise, idx) => (
                  <ExerciseItem key={idx}>
                    <ExerciseName>{exercise.name}</ExerciseName>
                    <ExerciseDetails>
                      Sets: {exercise.sets}, Reps: {exercise.reps}, Rest: {exercise.rest_time}
                    </ExerciseDetails>
                  </ExerciseItem>
                ))}
              </WorkoutDay>
            ))}
          </ResultContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Title>Workout Planner</Title>
      <TabContainer>
      <Tab
          onClick={() => setActiveTab('generateWorkoutPlan')}
          active={activeTab === 'generateWorkoutPlan'}
        >
          <FaClipboardList /> Generate Workout Plan
        </Tab>
        <Tab
          onClick={() => setActiveTab('exerciseDetails')}
          active={activeTab === 'exerciseDetails'}
        >
          <FaDumbbell /> Exercise Details
        </Tab>
        <Tab
          onClick={() => setActiveTab('nutritionAdvice')}
          active={activeTab === 'nutritionAdvice'}
        >
          <FaAppleAlt /> Nutrition Advice
        </Tab>
      </TabContainer>
      {renderForm()}
      {loading && (
        <LoadingSpinner>
          <FaSpinner /> Loading...
        </LoadingSpinner>
      )}
      {error && (
        <ErrorMessage
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          {error}
        </ErrorMessage>
      )}
      <AnimatePresence>
        {result && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            {renderResult()}
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: ${props => props.active ? '#4A25E1' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : '#333'};
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#7B5AFF' : '#e0e0e0'};
    transform: translateY(-2px);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #333;
  font-weight: 600;
`;

const Input = styled.input`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #4A25E1;
    outline: none;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;

  &:focus {
    border-color: #4A25E1;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #7B5AFF;
    transform: translateY(-2px);
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.2rem;
  color: #4A25E1;
  gap: 1rem;

  svg {
    font-size: 2rem;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled(motion.div)`
  color: #ff4d4d;
  text-align: center;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: #ffe6e6;
  border-radius: 5px;
  margin-top: 1rem;
`;

const ResultContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ResultTitle = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 700;
`;

const ResultSection = styled.div`
  margin-bottom: 1.5rem;
`;

const ResultLabel = styled.h3`
  font-size: 1.2rem;
  color: #4A25E1;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const ResultText = styled.p`
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
`;

const ResultList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ResultListItem = styled.li`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '•';
    color: #4A25E1;
    position: absolute;
    left: 0;
  }
`;

const MealSuggestion = styled.div`
  margin-bottom: 1.5rem;
`;

const MealTitle = styled.h4`
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const SuggestionItem = styled.div`
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 2px solid #4A25E1;
`;

const SuggestionName = styled.h5`
  font-size: 1rem;
  color: #4A25E1;
  margin-bottom: 0.25rem;
  font-weight: 600;
`;

const SuggestionCalories = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.25rem;
`;

const SuggestionIngredients = styled.p`
  font-size: 0.9rem;
  color: #333;
`;

const WorkoutDay = styled.div`
  margin-bottom: 2rem;
`;

const WorkoutDayTitle = styled.h3`
  font-size: 1.4rem;
  color: #4A25E1;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const ExerciseItem = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f5f5f7;
  border-radius: 5px;
`;

const ExerciseName = styled.h4`
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const ExerciseDetails = styled.p`
  font-size: 0.9rem;
  color: #666;
`;

export default WorkoutPlanner;