import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaDumbbell, FaRunning, FaChild, FaCheck, FaEdit, FaTrash, FaArrowLeft, FaPlus } from 'react-icons/fa';

const COLORS = ['#0088FE', '#000000', '#4A25E1', '#4A25E1'];

const Workouts = () => {
  const [workouts, setWorkouts] = useState([]);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, message: '', onConfirm: null });
  const [editMode, setEditMode] = useState({});


  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL || '';

  useEffect(() => {
    fetchWorkouts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/workouts`);
      setWorkouts(response.data);
    } catch (err) {
      console.error('Error fetching workouts:', err);
      setErrorMessage(`Error fetching workouts: ${err.message}`);
    }
  };

  const Notification = ({ message, type }) => (
    <NotificationWrapper className={type}>
      {message}
      <button onClick={() => type === 'success' ? setSuccessMessage(null) : setErrorMessage(null)}>
        Close
      </button>
    </NotificationWrapper>
  );

  const handleWorkoutClick = (workout) => {
    setSelectedWorkout(workout);
  };

  const handleExerciseUpdate = async (workoutId, exerciseId, updatedExercise) => {
    try {
      if (exerciseId.toString().startsWith('temp-')) {
        // This is a new exercise
        const response = await axios.post(`${API_BASE_URL}/workouts/${workoutId}/exercises`, updatedExercise);
        setSelectedWorkout(prevWorkout => ({
          ...prevWorkout,
          exercises: prevWorkout.exercises.map(ex => 
            ex.id === exerciseId ? {...response.data, id: response.data.id.toString()} : ex
          )
        }));
      } else {
        // This is an existing exercise
        await axios.put(`${API_BASE_URL}/workouts/${workoutId}/exercises/${exerciseId}`, updatedExercise);
      }
      fetchWorkouts();
      setSuccessMessage('Exercise updated successfully!');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating exercise:', err);
      setErrorMessage(`Error updating exercise: ${err.message}`);
    }
  };

  const handleWorkoutTypeUpdate = async (workoutId, newType) => {
    try {
      await axios.put(`${API_BASE_URL}/workouts/${workoutId}`, { type: newType });
      fetchWorkouts();
      setSuccessMessage('Workout type updated successfully!');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating workout type:', err);
      setErrorMessage(`Error updating workout type: ${err.message}`);
    }
  };

  const handleDeleteWorkout = async (workoutId) => {
    if (window.confirm('Are you sure you want to delete this workout?')) {
      try {
        await axios.delete(`${API_BASE_URL}/workouts/${workoutId}`);
        fetchWorkouts();
        setSelectedWorkout(null);
        setSuccessMessage('Workout deleted successfully!');
        setTimeout(() => setSuccessMessage(null), 3000);
      } catch (err) {
        console.error('Error deleting workout:', err);
        setErrorMessage(`Error deleting workout: ${err.message}`);
      }
    }
  };

  const handleRemoveExercise = (workoutId, exerciseId) => {
    setConfirmDialog({
      isOpen: true,
      message: 'Are you sure you want to remove this exercise?',
      onConfirm: async () => {
        try {
          await axios.delete(`${API_BASE_URL}/workouts/${workoutId}/exercises/${exerciseId}`);
          setWorkouts(prevWorkouts => 
            prevWorkouts.map(workout => 
              workout.id === workoutId 
                ? {...workout, exercises: workout.exercises.filter(ex => ex.id !== exerciseId)}
                : workout
            )
          );
          setSelectedWorkout(prevWorkout => ({
            ...prevWorkout,
            exercises: prevWorkout.exercises.filter(ex => ex.id !== exerciseId)
          }));
          setSuccessMessage('Exercise removed successfully!');
          setTimeout(() => setSuccessMessage(null), 3000);
        } catch (err) {
          console.error('Error removing exercise:', err);
          setErrorMessage(`Error removing exercise: ${err.message}`);
        }
        setConfirmDialog({ isOpen: false, message: '', onConfirm: null });
      }
    });
  };

  const handleUpdateWorkoutStatus = async (workoutId, newStatus) => {
    try {
      await axios.put(`${API_BASE_URL}/workouts/${workoutId}/status`, { status: newStatus });
      fetchWorkouts();
      setSuccessMessage('Workout status updated successfully!');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error('Error updating workout status:', err);
      setErrorMessage(`Error updating workout status: ${err.message}`);
    }
  };



  const ConfirmDialog = ({ message, isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null;
  
    return (
      <ConfirmDialogWrapper>
        <ConfirmDialogContent>
          <p>{message}</p>
          <ButtonGroup>
            <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
          </ButtonGroup>
        </ConfirmDialogContent>
      </ConfirmDialogWrapper>
    );
  };

  const addExerciseToWorkout = async (exercise) => {
    if (!selectedWorkout) {
      setErrorMessage('Please select a workout.');
      return;
    }
    
    try {
      const response = await axios.post(`${API_BASE_URL}/workouts/${selectedWorkout.id}/exercises`, {
        name: exercise.name,
        sets: 3,
        reps: 10,
        weight: 0,
      });

      if (response.status === 201) {
        setSuccessMessage(`${exercise.name} added to workout successfully!`);
        await fetchWorkouts();
      } else {
        setErrorMessage('Failed to add exercise to workout. Please try again.');
      }
    } catch (err) {
      console.error('Error adding exercise to workout:', err);
      setErrorMessage(`An error occurred while adding the exercise to the workout: ${err.message}`);
    }
  };

  const handleAddCustomExercise = () => {
    if (selectedWorkout) {
      const newExercise = {
        id: `temp-${Date.now()}`,
        name: '',
        sets: 0,
        reps: 0,
        weight: 0
      };
      setSelectedWorkout(prevWorkout => ({
        ...prevWorkout,
        exercises: [...prevWorkout.exercises, newExercise]
      }));
    }
  };

  return (
    <Container>
      <h2>Your Workouts</h2>
      <WorkoutGrid>
        {workouts.map((workout) => (
          <WorkoutCard
            key={workout.id}
            onClick={() => handleWorkoutClick(workout)}
            layoutId={`workout-${workout.id}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <WorkoutIcon type={workout.type} />
            <h3>{workout.name}</h3>
            <p>Type: {workout.type}</p>
            <p>Date: {new Date(workout.date).toLocaleDateString()}</p>
            <p>Status: {workout.status}</p>
          </WorkoutCard>
        ))}
      </WorkoutGrid>
      <AnimatePresence>
        {selectedWorkout && (
          <WorkoutDetails
            layoutId={`workout-${selectedWorkout.id}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BackButton onClick={() => setSelectedWorkout(null)}>
              <FaArrowLeft /> Back to Workouts
            </BackButton>
            <h3>{selectedWorkout.name}</h3>
            <p>Description: {selectedWorkout.description}</p>
            <p>Duration: {selectedWorkout.duration} minutes</p>
            <p>Status: {selectedWorkout.status}</p>
            <WorkoutTypeSelector
              value={selectedWorkout.type}
              onChange={(e) => handleWorkoutTypeUpdate(selectedWorkout.id, e.target.value)}
            >
              <option value="strength">Strength</option>
              <option value="cardio">Cardio</option>
              <option value="flexibility">Flexibility</option>
            </WorkoutTypeSelector>
            <WorkoutStatusSelector
              value={selectedWorkout.status}
              onChange={(e) => handleUpdateWorkoutStatus(selectedWorkout.id, e.target.value)}
            >
              <option value="planned">Planned</option>
              <option value="completed">Completed</option>
              <option value="skipped">Skipped</option>
            </WorkoutStatusSelector>
            <DeleteButton onClick={() => handleDeleteWorkout(selectedWorkout.id)}>
              <FaTrash /> Delete Workout
            </DeleteButton>
            <h4>Exercises:</h4>
            <ExerciseList>
            {selectedWorkout.exercises.map((exercise) => (
              <ExerciseItem key={exercise.id}>
                {editMode[exercise.id] ? (
                  <ExerciseForm
                    exercise={exercise}
                    onSave={(updatedExercise) => {
                      handleExerciseUpdate(selectedWorkout.id, exercise.id, updatedExercise);
                      setEditMode(prev => ({...prev, [exercise.id]: false}));
                    }}
                    onCancel={() => setEditMode(prev => ({...prev, [exercise.id]: false}))}
                  />
                ) : (
                  <>
                    <h4>{exercise.name || 'New Exercise'}</h4>
                    <p>Sets: {exercise.sets}</p>
                    <p>Reps: {exercise.reps}</p>
                    <p>Weight: {exercise.weight} lbs</p>
                    <ButtonGroup>
                      <UpdateButton onClick={() => setEditMode(prev => ({...prev, [exercise.id]: true}))}>
                        Edit
                      </UpdateButton>
                      <RemoveButton onClick={() => handleRemoveExercise(selectedWorkout.id, exercise.id)}>
                        Remove
                      </RemoveButton>
                    </ButtonGroup>
                  </>
                )}
              </ExerciseItem>
            ))}
            </ExerciseList>
            <AddCustomExerciseButton onClick={() => handleAddCustomExercise(selectedWorkout.id)}>
              <FaPlus /> Add Custom Exercise
            </AddCustomExerciseButton>
          </WorkoutDetails>
        )}
      </AnimatePresence>
      {successMessage && <Notification message={successMessage} type="success" />}
      {errorMessage && <Notification message={errorMessage} type="error" />}
      <ConfirmDialog
        isOpen={confirmDialog.isOpen}
        message={confirmDialog.message}
        onConfirm={confirmDialog.onConfirm}
        onCancel={() => setConfirmDialog({ isOpen: false, message: '', onConfirm: null })}
      />
    </Container>
  );
};

const ExerciseForm = ({ exercise, onSave, onCancel }) => {
  const [formData, setFormData] = useState(exercise);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="name">Name:</Label>
        <Input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="sets">Sets:</Label>
        <Input type="number" id="sets" name="sets" value={formData.sets} onChange={handleChange} min="0" required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="reps">Reps:</Label>
        <Input type="number" id="reps" name="reps" value={formData.reps} onChange={handleChange} min="0" required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="weight">Weight (lbs):</Label>
        <Input type="number" id="weight" name="weight" value={formData.weight} onChange={handleChange} min="0" step="0.1" required />
      </FormGroup>
      <ButtonGroup>
        <UpdateButton type="submit">Save</UpdateButton>
        <CancelButton type="button" onClick={onCancel}>Cancel</CancelButton>
      </ButtonGroup>
    </StyledForm>
  );
};

const WorkoutIcon = ({ type }) => {
  switch (type) {
    case 'strength':
      return <FaDumbbell />;
    case 'cardio':
      return <FaRunning />;
    case 'flexibility':
      return <FaChild />;
    default:
      return <FaDumbbell />;
  }
};

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const WorkoutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`;

const WorkoutCard = styled(motion.div)`
  background-color: ${COLORS[0]};
  color: white;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

const WorkoutDetails = styled(motion.div)`
  margin-top: 2rem;
  background-color: ${COLORS[1]};
  color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ExerciseList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ExerciseItem = styled.li`
  margin-bottom: 1rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const UpdateButton = styled(Button)`
  background-color: #4caf50;
  color: white;

  &:hover {
    background-color: #45a049;
  }
`;

const RemoveButton = styled(Button)`
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const NotificationWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 1rem;
  border-radius: 4px;
  color: white;
  z-index: 1000;

  &.success {
    background-color: #4caf50;
  }

  &.error {
    background-color: #f44336;
  }
`;

const WorkoutTypeSelector = styled.select`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
`;

const WorkoutStatusSelector = styled.select`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
`;

const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;

  &:hover {
    background-color: #d32f2f;
  }
`;

const BackButton = styled.button`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const AddCustomExerciseButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #218838;
  }
`;

const ConfirmDialogWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ConfirmDialogContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
`;

const ConfirmButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const CancelButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c82333;
  }
`;

export default Workouts;