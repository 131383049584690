import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaWeight, FaRuler, FaVenusMars, FaRunning, FaCalculator } from 'react-icons/fa';

const NutritionCalculator = () => {
  const [activeTab, setActiveTab] = useState('bmi');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const [bmiData, setBmiData] = useState({
    measurement_units: 'std',
    feet: 5,
    inches: 2,
    lbs: 120
  });

  const [nutritionData, setNutritionData] = useState({
    measurement_units: 'std',
    sex: 'female',
    age_value: 20,
    age_type: 'yrs',
    feet: 5,
    inches: 2,
    lbs: 120,
    activity_level: 'Active'
  });

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);
  
    try {
      let response;
      if (activeTab === 'bmi') {
        response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/bmi`, { 
          params: Object.fromEntries(Object.entries(bmiData).filter(([_, v]) => v != null))
        });
      } else {
        response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/nutrition-info`, { 
          params: Object.fromEntries(Object.entries(nutritionData).filter(([_, v]) => v != null))
        });
      }
      setResult(response.data);
    } catch (err) {
      console.error('API Error:', err);
      if (err.response) {
        setError(`Error ${err.response.status}: ${err.response.data.detail || err.response.data.message || JSON.stringify(err.response.data)}`);
      } else if (err.request) {
        setError('No response received from the server. Please try again later.');
      } else {
        setError('An error occurred while setting up the request. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderBMIForm = () => (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="measurement_units">
          <FaRuler /> Measurement Units
        </Label>
        <Select
          id="measurement_units"
          name="measurement_units"
          value={bmiData.measurement_units}
          onChange={(e) => handleInputChange(e, setBmiData)}
        >
          <option value="std">Standard</option>
          <option value="met">Metric</option>
        </Select>
      </FormGroup>
      {bmiData.measurement_units === 'std' ? (
        <>
          <FormGroup>
            <Label htmlFor="feet">Height (feet)</Label>
            <Input
              id="feet"
              type="number"
              name="feet"
              value={bmiData.feet}
              onChange={(e) => handleInputChange(e, setBmiData)}
              placeholder="Feet"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="inches">Height (inches)</Label>
            <Input
              id="inches"
              type="number"
              name="inches"
              value={bmiData.inches}
              onChange={(e) => handleInputChange(e, setBmiData)}
              placeholder="Inches"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lbs">
              <FaWeight /> Weight (lbs)
            </Label>
            <Input
              id="lbs"
              type="number"
              name="lbs"
              value={bmiData.lbs}
              onChange={(e) => handleInputChange(e, setBmiData)}
              placeholder="Weight (lbs)"
            />
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup>
            <Label htmlFor="cm">
              <FaRuler /> Height (cm)
            </Label>
            <Input
              id="cm"
              type="number"
              name="cm"
              value={bmiData.cm}
              onChange={(e) => handleInputChange(e, setBmiData)}
              placeholder="Height (cm)"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="kilos">
              <FaWeight /> Weight (kg)
            </Label>
            <Input
              id="kilos"
              type="number"
              name="kilos"
              value={bmiData.kilos}
              onChange={(e) => handleInputChange(e, setBmiData)}
              placeholder="Weight (kg)"
            />
          </FormGroup>
        </>
      )}
      <Button type="submit">Calculate BMI</Button>
    </Form>
  );

  const renderNutritionForm = () => (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="measurement_units">
          <FaRuler /> Measurement Units
        </Label>
        <Select
          id="measurement_units"
          name="measurement_units"
          value={nutritionData.measurement_units}
          onChange={(e) => handleInputChange(e, setNutritionData)}
        >
          <option value="std">Standard</option>
          <option value="met">Metric</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="sex">
          <FaVenusMars /> Sex
        </Label>
        <Select
          id="sex"
          name="sex"
          value={nutritionData.sex}
          onChange={(e) => handleInputChange(e, setNutritionData)}
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="age_value">Age</Label>
        <Input
          id="age_value"
          type="number"
          name="age_value"
          value={nutritionData.age_value}
          onChange={(e) => handleInputChange(e, setNutritionData)}
          placeholder="Age"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="age_type">Age Type</Label>
        <Select
          id="age_type"
          name="age_type"
          value={nutritionData.age_type}
          onChange={(e) => handleInputChange(e, setNutritionData)}
        >
          <option value="yrs">Years</option>
          <option value="mos">Months</option>
        </Select>
      </FormGroup>
      {nutritionData.measurement_units === 'std' ? (
        <>
          <FormGroup>
            <Label htmlFor="feet">Height (feet)</Label>
            <Input
              id="feet"
              type="number"
              name="feet"
              value={nutritionData.feet}
              onChange={(e) => handleInputChange(e, setNutritionData)}
              placeholder="Feet"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="inches">Height (inches)</Label>
            <Input
              id="inches"
              type="number"
              name="inches"
              value={nutritionData.inches}
              onChange={(e) => handleInputChange(e, setNutritionData)}
              placeholder="Inches"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lbs">
              <FaWeight /> Weight (lbs)
            </Label>
            <Input
              id="lbs"
              type="number"
              name="lbs"
              value={nutritionData.lbs}
              onChange={(e) => handleInputChange(e, setNutritionData)}
              placeholder="Weight (lbs)"
            />
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup>
            <Label htmlFor="cm">
              <FaRuler /> Height (cm)
            </Label>
            <Input
              id="cm"
              type="number"
              name="cm"
              value={nutritionData.cm}
              onChange={(e) => handleInputChange(e, setNutritionData)}
              placeholder="Height (cm)"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="kilos">
              <FaWeight /> Weight (kg)
            </Label>
            <Input
              id="kilos"
              type="number"
              name="kilos"
              value={nutritionData.kilos}
              onChange={(e) => handleInputChange(e, setNutritionData)}
              placeholder="Weight (kg)"
            />
          </FormGroup>
        </>
      )}
      <FormGroup>
        <Label htmlFor="activity_level">
          <FaRunning /> Activity Level
        </Label>
        <Select
          id="activity_level"
          name="activity_level"
          value={nutritionData.activity_level}
          onChange={(e) => handleInputChange(e, setNutritionData)}
        >
          <option value="Inactive">Inactive</option>
          <option value="Low Active">Low Active</option>
          <option value="Active">Active</option>
          <option value="Very Active">Very Active</option>
        </Select>
      </FormGroup>
      <Button type="submit">Calculate Nutrition Info</Button>
    </Form>
  );

  const renderResult = () => {
    if (!result) return null;

    if (activeTab === 'bmi') {
      return (
        <ResultContainer>
          <ResultTitle>BMI Result</ResultTitle>
          <ResultValue>{result.bmi.toFixed(2)}</ResultValue>
          <ResultCategory>
            Category: <strong>{getBMICategory(result.bmi)}</strong>
          </ResultCategory>
        </ResultContainer>
      );
    } else {
      return (
        <ResultContainer>
          <ResultTitle>Nutrition Information</ResultTitle>
          <ResultSection>
            <ResultSubtitle>BMI and Estimated Daily Caloric Needs</ResultSubtitle>
            <ResultItem>BMI: <strong>{result.BMI_EER.BMI.toFixed(2)}</strong></ResultItem>
            <ResultItem>Estimated Daily Caloric Needs: <strong>{result.BMI_EER["Estimated Daily Caloric Needs"]} calories</strong></ResultItem>
          </ResultSection>
          
          <ResultSection>
            <ResultSubtitle>Macronutrients</ResultSubtitle>
            <Table>
              <thead>
                <tr>
                  <th>Nutrient</th>
                  <th>Recommended Intake Per Day</th>
                </tr>
              </thead>
              <tbody>
                {result.macronutrients_table["macronutrients-table"].map((row, index) => (
                  <tr key={index}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ResultSection>
          
          <ResultSection>
            <ResultSubtitle>Essential Minerals</ResultSubtitle>
            <Table>
              <thead>
                <tr>
                  <th>Mineral</th>
                  <th>Recommended Intake Per Day</th>
                  <th>Tolerable UL Intake Per Day</th>
                </tr>
              </thead>
              <tbody>
                {result.minerals_table["essential-minerals-table"].map((row, index) => (
                  <tr key={index}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                    <td>{row[2]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ResultSection>

          <ResultSection>
            <ResultSubtitle>Non-Essential Minerals</ResultSubtitle>
            <Table>
              <thead>
                <tr>
                  <th>Mineral</th>
                  <th>Recommended Intake Per Day</th>
                  <th>Tolerable UL Intake Per Day</th>
                </tr>
              </thead>
              <tbody>
                {result.non_essential_minerals_table["non-essential-minerals-table"].map((row, index) => (
                  <tr key={index}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                    <td>{row[2]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ResultSection>

          <ResultSection>
            <ResultSubtitle>Vitamins</ResultSubtitle>
            <Table>
              <thead>
                <tr>
                  <th>Vitamin</th>
                  <th>Recommended Intake Per Day</th>
                  <th>Tolerable UL Intake Per Day</th>
                </tr>
              </thead>
              <tbody>
                {result.vitamins_table["vitamins-table"].map((row, index) => (
                  <tr key={index}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                    <td>{row[2]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ResultSection>
        </ResultContainer>
      );
    }
  };

  const getBMICategory = (bmi) => {
    if (bmi < 18.5) return "Underweight";
    if (bmi < 25) return "Normal weight";
    if (bmi < 30) return "Overweight";
    return "Obese";
  };

  return (
    <Container>
      <Title>Nutrition Calculator</Title>
      <Subtitle>Calculate your BMI and get personalized nutrition information</Subtitle>
      <TabContainer>
        <Tab active={activeTab === 'bmi'} onClick={() => setActiveTab('bmi')}>
          <FaCalculator /> BMI Calculator
        </Tab>
        <Tab active={activeTab === 'nutrition'} onClick={() => setActiveTab('nutrition')}>
          <FaCalculator /> Nutrition Info
        </Tab>
      </TabContainer>
      {activeTab === 'bmi' ? renderBMIForm() : renderNutritionForm()}
      <AnimatePresence>
        {loading && (
          <LoadingSpinner
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            Calculating...
          </LoadingSpinner>
        )}
        {error && (
          <ErrorMessage
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            {error}
          </ErrorMessage>
        )}
        {result && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            {renderResult()}
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
  margin-bottom: 2rem;
  text-align: center;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: ${props => props.active ? '#3498db' : '#ecf0f1'};
  color: ${props => props.active ? 'white' : '#34495e'};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${props => props.active ? '#2980b9' : '#bdc3c7'};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #3498db;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  background-color: white;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Button = styled.button`
  padding: 1rem;
  font-size: 1.1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

const LoadingSpinner = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.2rem;
  color: #3498db;
`;

const ErrorMessage = styled(motion.div)`
  color: #e74c3c;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fadbd8;
  border-radius: 5px;
`;

const ResultContainer = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-top: 2rem;
`;

const ResultTitle = styled.h2`
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
`;

const ResultValue = styled.p`
  font-size: 3rem;
  color: #3498db;
  text-align: center;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const ResultCategory = styled.p`
  font-size: 1.2rem;
  color: #34495e;
  text-align: center;
`;

const ResultSection = styled.div`
  margin-bottom: 2rem;
`;

const ResultSubtitle = styled.h3`
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const ResultItem = styled.p`
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 0.5rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  th, td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ecf0f1;
  }

  th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #2c3e50;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

export default NutritionCalculator;