import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { FaDumbbell, FaAppleAlt, FaChartPie, FaBolt, FaCalendarPlus, FaPlusCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Profile = () => {
  const [recentWorkouts, setRecentWorkouts] = useState([]);
  const [recentMeals, setRecentMeals] = useState([]);
  const [nutritionSummary, setNutritionSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/New_York',
      hour12: true
    }) + ' EST';
  };


  


  

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        };
  
        const [workoutsResponse, mealsResponse, nutritionResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/recent-workouts`, { headers }).catch(() => ({ data: [] })),
          axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/recent-meals`, { headers }).catch(() => ({ data: [] })),
          axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/nutrition-summary`, { headers }).catch(() => ({ data: null }))
        ]);

        setRecentWorkouts(workoutsResponse.data);
        setRecentMeals(mealsResponse.data);
        setNutritionSummary(nutritionResponse.data);
      } catch (err) {
        console.error('Dashboard data fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) return <LoadingMessage>Loading your fitness journey...</LoadingMessage>;

  return (
    <ProfileContainer>
      <ProfileHeader>
        <h1>Profile</h1>
        <p>Track, Plan, Achieve</p>
      </ProfileHeader>
      
      <QuickActionsBar>
      <QuickActionButton to="/create-workout">
        <FaPlusCircle />
        Create Workout
      </QuickActionButton>
      <QuickActionButton to="/create-meal">
        <FaPlusCircle />
        Create Meal
      </QuickActionButton>
      <QuickActionButton to="/calendar">
        <FaPlusCircle />
        View Calendar
      </QuickActionButton>
    </QuickActionsBar>
      
      <WidgetContainer>
        <Widget>
        <WidgetHeader>
          <FaDumbbell />
          <h2>Recent Workouts</h2>
        </WidgetHeader>
        {recentWorkouts.length > 0 ? (
          <WorkoutList>
            {recentWorkouts.map((workout, index) => (
              <WorkoutItem key={index} as={motion.div} whileHover={{ scale: 1.05 }}>
                <WorkoutName>{workout.name}</WorkoutName>
                <WorkoutDate>{formatDate(workout.date)}</WorkoutDate>
                <WorkoutDescription>{workout.description}</WorkoutDescription>
              </WorkoutItem>
            ))}
          </WorkoutList>
        ) : (
          <EmptyMessage>No recent workouts. Start your fitness journey today!</EmptyMessage>
        )}
          <ButtonGroup>
            <ActionButton to="/log-workout/gym">Log Gym Workout</ActionButton>
            <ActionButton to="/log-workout/cardio">Log Cardio</ActionButton>
          </ButtonGroup>
          <SecondaryButton to="/workout-planner">Plan a Workout</SecondaryButton>
        </Widget>

        <Widget>
          <WidgetHeader>
            <FaAppleAlt />
            <h2>Recent Meals</h2>
          </WidgetHeader>
          {recentMeals.length > 0 ? (
            <MealList>
              {recentMeals.map((meal, index) => (
                <MealItem key={index} as={motion.div} whileHover={{ scale: 1.05 }}>
                  <MealName>{meal.name}</MealName>
                  <MealCalories>{meal.calories} calories</MealCalories>
                  <MealDescription>{meal.description}</MealDescription>
                </MealItem>
              ))}
            </MealList>
          ) : (
            <EmptyMessage>No recent meals logged. Start tracking your nutrition!</EmptyMessage>
          )}
          <ActionButton to="/log-meal">Log a Meal</ActionButton>
          <SecondaryButton to="/diet-planner">Plan Your Diet</SecondaryButton>
        </Widget>

        <Widget>
          <WidgetHeader>
            <FaChartPie />
            <h2>Nutrition Summary</h2>
          </WidgetHeader>
          {nutritionSummary ? (
            <NutritionGrid>
              <NutritionItem>
                <NutritionLabel>Calories</NutritionLabel>
                <NutritionValue>{nutritionSummary.calories}</NutritionValue>
              </NutritionItem>
              <NutritionItem>
                <NutritionLabel>Protein</NutritionLabel>
                <NutritionValue>{nutritionSummary.protein}g</NutritionValue>
              </NutritionItem>
              <NutritionItem>
                <NutritionLabel>Carbs</NutritionLabel>
                <NutritionValue>{nutritionSummary.carbs}g</NutritionValue>
              </NutritionItem>
              <NutritionItem>
                <NutritionLabel>Fat</NutritionLabel>
                <NutritionValue>{nutritionSummary.fat}g</NutritionValue>
              </NutritionItem>
            </NutritionGrid>
          ) : (
            <EmptyMessage>No nutrition data available. Calculate your nutrition needs!</EmptyMessage>
          )}
          <ActionButton to="/nutrition-calculator">Calculate Nutrition</ActionButton>
        </Widget>
      </WidgetContainer>
      <Widget>
          <WidgetHeader>
            <FaBolt />
            <h2>Quick Actions</h2>
          </WidgetHeader>
          <QuickLinksGrid>
            <QuickLink to="/exercises">Exercise Database</QuickLink>
            <QuickLink to="/workout-planner">Workout Planner</QuickLink>
            <QuickLink to="/diet-planner">Diet Planner</QuickLink>
            <QuickLink to="/nutrition-calculator">Nutrition Calculator</QuickLink>
          </QuickLinksGrid>
        </Widget>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const ProfileHeader = styled.header`
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  p {
    font-size: 1.2rem;
    color: #34495e;
    font-weight: 300;
  }
`;

const QuickActionsBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const QuickActionButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const WidgetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const Widget = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    color: #3498db;
  }

  h2 {
    font-size: 1.4rem;
    color: #2c3e50;
    margin: 0;
    font-weight: 600;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-top: 4rem;
  color: #3498db;
`;

const EmptyMessage = styled.div`
  font-style: italic;
  color: #7f8c8d;
  margin-bottom: 1rem;
  text-align: center;
`;

const WorkoutName = styled.h3`
  font-size: 1.1rem;
  color: #3498db;
  margin: 0;
`;

const WorkoutDate = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
  margin: 0.25rem 0;
`;

const WorkoutList = styled.div`
  margin-bottom: 1rem;
`;

const WorkoutItem = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f1f3f5;
  border-radius: 10px;
  cursor: pointer;
`;

const WorkoutType = styled.h3`
  font-size: 1.1rem;
  color: #3498db;
  margin: 0;
`;

const WorkoutDescription = styled.p`
  font-size: 0.9rem;
  color: #34495e;
  margin: 0.5rem 0 0 0;
`;

const MealList = styled(WorkoutList)``;

const MealItem = styled(WorkoutItem)``;

const MealName = styled(WorkoutType)``;

const MealCalories = styled(WorkoutDate)``;

const MealDescription = styled(WorkoutDescription)``;

const NutritionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const NutritionItem = styled.div`
  text-align: center;
  padding: 1rem;
  background-color: #f1f3f5;
  border-radius: 10px;
`;

const NutritionLabel = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
  margin: 0;
`;

const NutritionValue = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #3498db;
  margin: 0.25rem 0 0 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const ActionButton = styled(Link)`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s ease;
  text-align: center;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

const SecondaryButton = styled(ActionButton)`
  background-color: transparent;
  color: #3498db;
  border: 2px solid #3498db;

  &:hover {
    background-color: #3498db;
    color: white;
  }
`;

  const QuickLinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
`;


    const QuickLink = styled(Link)`
    display: block;
    padding: 1rem;
    background-color: #f8f8f8;
    color: #4A25E1;
    text-decoration: none;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    transition: all 0.3s ease;

    &:hover {
      background-color: #4A25E1;
      color: white;
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(74, 37, 225, 0.2);
    }
    `;

export default Profile;






