import React, { useState } from 'react';
import styled from 'styled-components';
import { FaDumbbell, FaPlus, FaSave } from 'react-icons/fa';
import axios from 'axios';

const CreateWorkout = () => {
  const [workoutName, setWorkoutName] = useState('');
  const [exercises, setExercises] = useState([]);
  const [currentExercise, setCurrentExercise] = useState({ name: '', sets: '', reps: '', weight: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const addExercise = () => {
    if (currentExercise.name && currentExercise.sets && currentExercise.reps) {
      setExercises([...exercises, currentExercise]);
      setCurrentExercise({ name: '', sets: '', reps: '', weight: '' });
    }
  };

  const saveWorkout = async () => {
    if (!workoutName || exercises.length === 0) {
      setError('Please provide a workout name and at least one exercise.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/workouts`, {
        name: workoutName,
        exercises: exercises
      }, {
        headers: {
          'Content-Type': 'application/json',
          // Add authorization header if required
          // 'Authorization': `Bearer ${yourAuthToken}`
        }
      });

      if (response.status === 201) {
        setSuccess('Workout created successfully!');
        setWorkoutName('');
        setExercises([]);
        setError('');
      } else {
        setError('Failed to create workout. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while saving the workout. Please try again.');
      console.error('Error saving workout:', err);
    }
  };

  return (
    <Container>
      <Header>
        <FaDumbbell />
        <h1>Create New Workout</h1>
      </Header>
      <WorkoutForm>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        <Input
          type="text"
          placeholder="Workout Name"
          value={workoutName}
          onChange={(e) => setWorkoutName(e.target.value)}
        />
        <ExerciseInput>
          <Input
            type="text"
            placeholder="Exercise Name"
            value={currentExercise.name}
            onChange={(e) => setCurrentExercise({...currentExercise, name: e.target.value})}
          />
          <Input
            type="number"
            placeholder="Sets"
            value={currentExercise.sets}
            onChange={(e) => setCurrentExercise({...currentExercise, sets: e.target.value})}
          />
          <Input
            type="number"
            placeholder="Reps"
            value={currentExercise.reps}
            onChange={(e) => setCurrentExercise({...currentExercise, reps: e.target.value})}
          />
          <Input
            type="number"
            placeholder="Weight (optional)"
            value={currentExercise.weight}
            onChange={(e) => setCurrentExercise({...currentExercise, weight: e.target.value})}
          />
          <Button onClick={addExercise}><FaPlus /> Add Exercise</Button>
        </ExerciseInput>
        <ExerciseList>
          {exercises.map((exercise, index) => (
            <ExerciseItem key={index}>
              <span>{exercise.name}</span>
              <span>{exercise.sets} sets</span>
              <span>{exercise.reps} reps</span>
              {exercise.weight && <span>{exercise.weight} lbs</span>}
            </ExerciseItem>
          ))}
        </ExerciseList>
        <SaveButton onClick={saveWorkout}><FaSave /> Save Workout</SaveButton>
      </WorkoutForm>
    </Container>
  );
};



const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  
  h1 {
    margin-left: 1rem;
    color: #2c3e50;
  }

  svg {
    font-size: 2rem;
    color: #3498db;
  }
`;

const WorkoutForm = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ExerciseInput = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const SaveButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
`;

const ExerciseList = styled.div`
  margin-top: 2rem;
`;

const ExerciseItem = styled.div`
  background-color: #f8f9fa;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-bottom: 1rem;
`;

export default CreateWorkout;