import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [weightData, setWeightData] = useState({ labels: [], datasets: [] });
  const [workoutData, setWorkoutData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    fetchUserData();
    fetchWeightData();
    fetchWorkoutData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/user-profile`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchWeightData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/weight-data`);
      setWeightData({
        labels: response.data.map(d => d.date),
        datasets: [{
          label: 'Weight',
          data: response.data.map(d => d.weight),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      });
    } catch (error) {
      console.error('Error fetching weight data:', error);
    }
  };

  const fetchWorkoutData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/workout-data`);
      setWorkoutData({
        labels: response.data.map(d => d.date),
        datasets: [{
          label: 'Workout Duration (minutes)',
          data: response.data.map(d => d.duration),
          borderColor: 'rgb(255, 99, 132)',
          tension: 0.1
        }]
      });
    } catch (error) {
      console.error('Error fetching workout data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/user-profile`, user);
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  if (!user) return <div>Loading...</div>;

  return (
    <ProfileContainer>
      <h1>User Profile</h1>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={user.name}
          onChange={(e) => setUser({...user, name: e.target.value})}
          placeholder="Name"
        />
        <Input
          type="email"
          value={user.email}
          onChange={(e) => setUser({...user, email: e.target.value})}
          placeholder="Email"
        />
        <Input
          type="number"
          value={user.age}
          onChange={(e) => setUser({...user, age: e.target.value})}
          placeholder="Age"
        />
        <Input
          type="number"
          value={user.height}
          onChange={(e) => setUser({...user, height: e.target.value})}
          placeholder="Height (cm)"
        />
        <Input
          type="number"
          value={user.weight}
          onChange={(e) => setUser({...user, weight: e.target.value})}
          placeholder="Weight (kg)"
        />
        <Input
          type="text"
          value={user.fitnessGoal}
          onChange={(e) => setUser({...user, fitnessGoal: e.target.value})}
          placeholder="Fitness Goal"
        />
        <Button type="submit">Update Profile</Button>
      </Form>

      <ChartContainer>
        <h2>Weight Progress</h2>
        <Line data={weightData} />
      </ChartContainer>

      <ChartContainer>
        <h2>Workout Duration Progress</h2>
        <Line data={workoutData} />
      </ChartContainer>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #4A25E1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3a1db1;
  }
`;

const ChartContainer = styled.div`
  margin-top: 2rem;
`;

export default UserProfile;