import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL || '';

const Dashboard = () => {
  const [goals, setGoals] = useState([]);
  const [workoutStats, setWorkoutStats] = useState({
    total_workouts: 0,
    avg_duration: 0,
    total_calories_burned: 0
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dashboard`);
      setGoals(response.data.goals);
      setWorkoutStats(response.data.workout_stats);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setErrorMessage(`Error fetching dashboard data: ${error.message}`);
    }
  };

  return (
    <DashboardContainer>
      <h1>Dashboard</h1>
      
      <Section>
        <h2>Goals</h2>
        {goals.map((goal) => (
          <GoalItem key={goal.id}>
            <p>{goal.goal_type}: {goal.current_value} / {goal.target_value} {goal.unit}</p>
          </GoalItem>
        ))}
      </Section>

      <Section>
        <h2>Workout Stats</h2>
        <p>Total Workouts: {workoutStats.total_workouts}</p>
        <p>Avg. Duration: {workoutStats.avg_duration} min</p>
        <p>Calories Burned: {workoutStats.total_calories_burned}</p>
      </Section>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const GoalItem = styled.div`
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 20px;
`;

export default Dashboard;