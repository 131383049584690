import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUtensils, FaPlus, FaSave } from 'react-icons/fa';
import axios from 'axios';

const CreateMeal = () => {
  const [meal, setMeal] = useState({
    name: '',
    meal_type: 'breakfast',
    calories: '',
    protein: '',
    carbs: '',
    fat: '',
  });
  const [foods, setFoods] = useState([]);
  const [currentFood, setCurrentFood] = useState({ name: '', quantity: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleMealChange = (e) => {
    setMeal({ ...meal, [e.target.name]: e.target.value });
  };

  const addFood = () => {
    if (currentFood.name && currentFood.quantity) {
      setFoods([...foods, currentFood]);
      setCurrentFood({ name: '', quantity: '' });
    }
  };

  const saveMeal = async () => {
    if (!meal.name || foods.length === 0) {
      setError('Please provide a meal name and at least one food item.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/meals`, {
        ...meal,
        calories: parseInt(meal.calories),
        protein: parseFloat(meal.protein),
        carbs: parseFloat(meal.carbs),
        fat: parseFloat(meal.fat),
        foods: foods
      }, {
        headers: {
          'Content-Type': 'application/json',
          // Add authorization header if required
          // 'Authorization': `Bearer ${yourAuthToken}`
        }
      });

      if (response.status === 201) {
        setSuccess('Meal created successfully!');
        setMeal({
          name: '',
          meal_type: 'breakfast',
          calories: '',
          protein: '',
          carbs: '',
          fat: '',
        });
        setFoods([]);
        setError('');
      } else {
        setError('Failed to create meal. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while saving the meal. Please try again.');
      console.error('Error saving meal:', err);
    }
  };

  return (
    <Container>
      <Header>
        <FaUtensils />
        <h1>Create New Meal</h1>
      </Header>
      <MealForm>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        <Input
          type="text"
          name="name"
          placeholder="Meal Name"
          value={meal.name}
          onChange={handleMealChange}
        />
        <Select name="meal_type" value={meal.meal_type} onChange={handleMealChange}>
          <option value="breakfast">Breakfast</option>
          <option value="lunch">Lunch</option>
          <option value="dinner">Dinner</option>
          <option value="snack">Snack</option>
        </Select>
        <Input
          type="number"
          name="calories"
          placeholder="Calories"
          value={meal.calories}
          onChange={handleMealChange}
        />
        <Input
          type="number"
          name="protein"
          placeholder="Protein (g)"
          value={meal.protein}
          onChange={handleMealChange}
        />
        <Input
          type="number"
          name="carbs"
          placeholder="Carbs (g)"
          value={meal.carbs}
          onChange={handleMealChange}
        />
        <Input
          type="number"
          name="fat"
          placeholder="Fat (g)"
          value={meal.fat}
          onChange={handleMealChange}
        />
        <FoodInput>
          <Input
            type="text"
            placeholder="Food Name"
            value={currentFood.name}
            onChange={(e) => setCurrentFood({...currentFood, name: e.target.value})}
          />
          <Input
            type="number"
            placeholder="Quantity"
            value={currentFood.quantity}
            onChange={(e) => setCurrentFood({...currentFood, quantity: e.target.value})}
          />
          <Button onClick={addFood}><FaPlus /> Add Food</Button>
        </FoodInput>
        <FoodList>
          {foods.map((food, index) => (
            <FoodItem key={index}>
              <span>{food.name}</span>
              <span>{food.quantity}</span>
            </FoodItem>
          ))}
        </FoodList>
        <SaveButton onClick={saveMeal}><FaSave /> Save Meal</SaveButton>
      </MealForm>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff; /* White background */
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  
  h1 {
    margin-left: 1rem;
    color: #000000; /* Black text */
  }

  svg {
    font-size: 2rem;
    color: #3498db; /* Blue icon */
  }
`;

const MealForm = styled.div`
  background-color: #ffffff; /* White background */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #3498db; /* Blue border */
  border-radius: 4px;
  color: #000000; /* Black text */

  &:focus {
    outline: none;
    border-color: #3498db; /* Blue border on focus */
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #3498db; /* Blue border */
  border-radius: 4px;
  color: #000000; /* Black text */
  background-color: white;

  &:focus {
    outline: none;
    border-color: #3498db; /* Blue border on focus */
  }
`;

const Button = styled.button`
  background-color: #3498db; /* Blue background */
  color: #ffffff; /* White text */
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9; /* Darker blue background on hover */
  }
`;

const SaveButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
`;

const FoodInput = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const FoodList = styled.div`
  margin-top: 2rem;
`;

const FoodItem = styled.div`
  background-color: #f8f9fa; /* Light grey background */
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  color: #000000; /* Black text */
`;

const ErrorMessage = styled.div`
  color: #e74c3c; /* Red text */
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.div`
  color: #28a745; /* Green text */
  margin-bottom: 1rem;
`;

export default CreateMeal;