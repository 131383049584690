import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaCalendarAlt, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const UserCalendar = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ title: '', date: '', event_type: 'custom' });
  
  useEffect(() => {
    fetchEvents();
  }, []);
  
  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/calendar-events/`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const addEvent = async () => {
    if (newEvent.title && newEvent.date) {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/calendar-events/`, newEvent);
        fetchEvents();
        setNewEvent({ title: '', date: '', event_type: 'custom' });
      } catch (error) {
        console.error('Error adding event:', error);
      }
    }
  };

  const renderCalendarDays = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<DayCell key={`empty-${i}`} />);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const dateString = date.toISOString().split('T')[0];
      const dayEvents = events.filter(event => event.date.startsWith(dateString));
      const isToday = date.toDateString() === new Date().toDateString();
      days.push(
        <DayCell key={day} $isToday={isToday}>
          <DayNumber>{day}</DayNumber>
          {dayEvents.map((event, index) => (
            <EventItem key={index} eventType={event.event_type}>
              {event.title}
            </EventItem>
          ))}
        </DayCell>
      );
    }
    return days;
  };

  return (
    <Container>
      <Header>
        <FaCalendarAlt />
        <h1>Fitness Calendar</h1>
      </Header>
      <CurrentDateDisplay>
        {currentDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
      </CurrentDateDisplay>
      <EventForm>
        <Input
          type="text"
          placeholder="Event Title"
          value={newEvent.title}
          onChange={(e) => setNewEvent({...newEvent, title: e.target.value})}
        />
        <Input
          type="date"
          value={newEvent.date}
          onChange={(e) => setNewEvent({...newEvent, date: e.target.value})}
        />
        <Select
          value={newEvent.event_type}
          onChange={(e) => setNewEvent({...newEvent, event_type: e.target.value})}
        >
          <option value="custom">Custom</option>
          <option value="workout">Workout</option>
          <option value="meal">Meal</option>
        </Select>
        <Button onClick={addEvent}><FaPlus /> Add Event</Button>
      </EventForm>
      <CalendarGrid>
        <WeekdayHeader>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <WeekdayCell key={day}>{day}</WeekdayCell>
          ))}
        </WeekdayHeader>
        {renderCalendarDays()}
      </CalendarGrid>
    </Container>
  );
};

// Styled components (unchanged)
const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  
  h1 {
    margin-left: 1rem;
    color: #2c3e50;
  }

  svg {
    font-size: 2rem;
    color: #3498db;
  }
`;

const CurrentDateDisplay = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #3498db;
  font-weight: bold;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #ddd;
  border: 1px solid #ddd;
`;

const WeekdayHeader = styled.div`
  display: contents;
`;

const WeekdayCell = styled.div`
  background-color: #f8f9fa;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
`;

const DayCell = styled.div`
  background-color: ${props => props.$isToday ? '#e8f4fd' : 'white'};
  min-height: 100px;
  padding: 0.5rem;
  border: ${props => props.$isToday ? '2px solid #3498db' : 'none'};
`;

const DayNumber = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const EventItem = styled.div`
  background-color: ${props => 
    props.eventType === 'workout' ? '#3498db' : 
    props.eventType === 'meal' ? '#e74c3c' : 
    '#2ecc71'
  };
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
`;

const EventForm = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex: 1;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;

  &:hover {
    background-color: #2980b9;
  }
`;

export default UserCalendar;