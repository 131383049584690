import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Layout from './components/Layout';
import Register from "./components/Register";
import SignIn from "./components/Signin";
import VerifyLogin from "./components/VerifyLogin";
import VerifyRegister from "./components/VerifyRegister";
import { UserProvider } from "./components/UserContext";
import Dashboard from "./components/Dashboard";
import ExerciseDB from './components/ExerciseDB';
import DietPlanner from './components/DietPlanner';
import WorkoutPlanner from './components/WorkoutPlanner';
import NutritionCalculator from './components/NutritionCalculator';
import Profile from './components/Profile';
import LogMeal from './components/LogMeal';
import LogWorkoutCardio from './components/LogWorkoutCardio';
import LogWorkoutGym from './components/LogWorkoutGym';
import UserProfile from './components/UserProfile';
import CreateMeal from './components/CreateMeal';
import CreateWorkout from './components/CreateWorkout';
import UserCalendar from './components/UserCalendar';
import Jamiro from './components/Jamiro';
import Goals from './components/Goals';
import Workouts from './components/Workouts'; // Import the new WorkoutList component

function App() {
  const [filters, setFilters] = useState({});

  const handleSearch = (filterData) => {
    setFilters(filterData);
  };

  return (
    <Router>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-login/:token" element={<VerifyLogin />} />
            <Route path="/exercises" element={<ExerciseDB />} />
            <Route path="/workout-planner" element={<WorkoutPlanner />} />
            <Route path="/diet-planner" element={<DietPlanner />} />
            <Route path="/nutrition-calculator" element={<NutritionCalculator />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/log-meal" element={<LogMeal />} />
            <Route path="/log-workout/cardio" element={<LogWorkoutCardio />} />
            <Route path="/log-workout/gym" element={<LogWorkoutGym />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/goals" element={<Goals />} />
            <Route path="/workouts" element={<Workouts />} />
            <Route path="/create-meal" element={<CreateMeal />} />
            <Route path="/create-workout" element={<CreateWorkout />} />
            <Route path="/calendar" element={<UserCalendar />} />
            <Route path="/jamiroperpageurl" element={<Jamiro />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </Layout>
      </UserProvider>
    </Router>
  );
}

export default App;