import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus, FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';

const COLORS = ['#0088FE', '#000000', '#4A25E1', '#4A25E1'];

const Goals = () => {
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState({
    goal_type: 'weight',
    target_value: '',
    current_value: '',
    unit: '',
    target_date: '',
    notes: ''
  });
  const [editingGoal, setEditingGoal] = useState(null);
  const [isAddingGoal, setIsAddingGoal] = useState(false);

  useEffect(() => {
    fetchGoals();
  }, []);

  const fetchGoals = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      const data = await response.json();
      setGoals(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching goals:', error);
      setGoals([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoal(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(newGoal)
      });
      if (response.ok) {
        fetchGoals();
        setNewGoal({
          goal_type: 'weight',
          target_value: '',
          current_value: '',
          unit: '',
          target_date: '',
          notes: ''
        });
        setIsAddingGoal(false);
      }
    } catch (error) {
      console.error('Error creating goal:', error);
    }
  };

  const handleEdit = async (id, updatedGoal) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(updatedGoal)
      });
      if (response.ok) {
        fetchGoals();
        setEditingGoal(null);
      }
    } catch (error) {
      console.error('Error updating goal:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/goals/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (response.ok) {
        fetchGoals();
      }
    } catch (error) {
      console.error('Error deleting goal:', error);
    }
  };

  return (
    <GoalsContainer>
      <Header>
        <h1>Your Fitness Goals</h1>
        <AddButton onClick={() => setIsAddingGoal(true)}>
          <FaPlus /> Add New Goal
        </AddButton>
      </Header>

      {isAddingGoal && (
        <GoalForm onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Goal Type</Label>
            <Select name="goal_type" value={newGoal.goal_type} onChange={handleInputChange}>
              <option value="weight">Weight</option>
              <option value="strength">Strength</option>
              <option value="endurance">Endurance</option>
              <option value="nutrition">Nutrition</option>
              <option value="habit">Habit</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label>Target Value</Label>
            <Input
              type="number"
              name="target_value"
              value={newGoal.target_value}
              onChange={handleInputChange}
              placeholder="Enter target value"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Current Value</Label>
            <Input
              type="number"
              name="current_value"
              value={newGoal.current_value}
              onChange={handleInputChange}
              placeholder="Enter current value"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Unit</Label>
            <Input
              type="text"
              name="unit"
              value={newGoal.unit}
              onChange={handleInputChange}
              placeholder="e.g., kg, miles, minutes"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Target Date</Label>
            <Input
              type="date"
              name="target_date"
              value={newGoal.target_date}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Notes</Label>
            <Textarea
              name="notes"
              value={newGoal.notes}
              onChange={handleInputChange}
              placeholder="Additional notes or motivation"
            />
          </FormGroup>
          <ButtonGroup>
            <SubmitButton type="submit">Save Goal</SubmitButton>
            <CancelButton type="button" onClick={() => setIsAddingGoal(false)}>Cancel</CancelButton>
          </ButtonGroup>
        </GoalForm>
      )}

      <GoalsList>
        {goals.length > 0 ? (
          goals.map((goal) => (
            <GoalItem key={goal.id}>
              {editingGoal === goal.id ? (
  <GoalForm onSubmit={(e) => {
    e.preventDefault();
    handleEdit(goal.id, newGoal);
  }}>
    <FormGroup>
      <Label>Goal Type</Label>
      <Select name="goal_type" value={newGoal.goal_type} onChange={handleInputChange}>
        <option value="weight">Weight</option>
        <option value="strength">Strength</option>
        <option value="endurance">Endurance</option>
        <option value="nutrition">Nutrition</option>
        <option value="habit">Habit</option>
      </Select>
    </FormGroup>
    <FormGroup>
      <Label>Target Value</Label>
      <Input
        type="number"
        name="target_value"
        value={newGoal.target_value}
        onChange={handleInputChange}
        required
      />
    </FormGroup>
    <FormGroup>
      <Label>Current Value</Label>
      <Input
        type="number"
        name="current_value"
        value={newGoal.current_value}
        onChange={handleInputChange}
        required
      />
    </FormGroup>
    <FormGroup>
      <Label>Unit</Label>
      <Input
        type="text"
        name="unit"
        value={newGoal.unit}
        onChange={handleInputChange}
        required
      />
    </FormGroup>
    <FormGroup>
      <Label>Target Date</Label>
      <Input
        type="date"
        name="target_date"
        value={newGoal.target_date}
        onChange={handleInputChange}
        required
      />
    </FormGroup>
    <FormGroup>
      <Label>Notes</Label>
      <Textarea
        name="notes"
        value={newGoal.notes}
        onChange={handleInputChange}
      />
    </FormGroup>
    <ButtonGroup>
      <SubmitButton type="submit"><FaCheck /> Save</SubmitButton>
      <CancelButton type="button" onClick={() => setEditingGoal(null)}><FaTimes /> Cancel</CancelButton>
    </ButtonGroup>
  </GoalForm>
) : (
                <>
                  <GoalType>{goal.goal_type}</GoalType>
                  <GoalDetails>
                    <p><strong>Target:</strong> {goal.target_value} {goal.unit}</p>
                    <p><strong>Current:</strong> {goal.current_value} {goal.unit}</p>
                    <p><strong>Date:</strong> {new Date(goal.target_date).toLocaleDateString()}</p>
                    <p><strong>Status:</strong> {goal.status}</p>
                    {goal.notes && <p><strong>Notes:</strong> {goal.notes}</p>}
                  </GoalDetails>
                  <ButtonGroup>
                    <EditButton onClick={() => {
                      setEditingGoal(goal.id);
                      setNewGoal(goal);
                    }}><FaEdit /> Edit</EditButton>
                    <DeleteButton onClick={() => handleDelete(goal.id)}><FaTrash /> Delete</DeleteButton>
                  </ButtonGroup>
                </>
              )}
            </GoalItem>
          ))
        ) : (
          <NoGoals>No goals set yet. Start by adding a new goal!</NoGoals>
        )}
      </GoalsList>
    </GoalsContainer>
  );
};

const GoalsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    font-size: 2.5rem;
    color: ${COLORS[0]};
  }
`;

const AddButton = styled.button`
  background-color: ${COLORS[1]};
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${COLORS[2]};
  }
`;

const GoalForm = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${COLORS[0]};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 100px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const SubmitButton = styled(Button)`
  background-color: ${COLORS[1]};
  color: white;

  &:hover {
    background-color: ${COLORS[2]};
  }
`;

const CancelButton = styled(Button)`
  background-color: ${COLORS[3]};
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const EditButton = styled(Button)`
  background-color: ${COLORS[0]};
  color: white;

  &:hover {
    background-color: #1976D2;
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${COLORS[3]};
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const GoalsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const GoalItem = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const GoalType = styled.h3`
  font-size: 1.2rem;
  color: ${COLORS[0]};
  margin-bottom: 10px;
  text-transform: capitalize;
`;

const GoalDetails = styled.div`
  margin-bottom: 15px;

  p {
    margin: 5px 0;
    color: #666;
  }

  strong {
    color: ${COLORS[2]};
  }
`;

const NoGoals = styled.p`
  text-align: center;
  color: #666;
  font-style: italic;
  grid-column: 1 / -1;
`;

export default Goals;