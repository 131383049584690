import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const LogWorkoutCardio = () => {
  const [workoutName, setWorkoutName] = useState('');
  const [duration, setDuration] = useState('');
  const [distance, setDistance] = useState('');
  const [caloriesBurned, setCaloriesBurned] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/workouts`, {
        name: workoutName,
        type: 'cardio',
        duration: parseInt(duration),
        distance: parseFloat(distance),
        caloriesBurned: parseInt(caloriesBurned)
      });
      navigate('/');  // Redirect to dashboard after successful submission
    } catch (err) {
      console.error('Error logging workout:', err);
    }
  };

  return (
    <FormContainer>
      <h2>Log Cardio Workout</h2>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={workoutName}
          onChange={(e) => setWorkoutName(e.target.value)}
          placeholder="Workout Name (e.g., Running, Cycling)"
          required
        />
        <Input
          type="number"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          placeholder="Duration (minutes)"
          required
        />
        <Input
          type="number"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
          placeholder="Distance (miles)"
          step="0.1"
          required
        />
        <Input
          type="number"
          value={caloriesBurned}
          onChange={(e) => setCaloriesBurned(e.target.value)}
          placeholder="Calories Burned"
          required
        />
        <SubmitButton type="submit">Log Workout</SubmitButton>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #4A25E1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3a1db1;
  }
`;

export default LogWorkoutCardio;