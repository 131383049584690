import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const LogWorkoutGym = () => {
  const [workoutName, setWorkoutName] = useState('');
  const [duration, setDuration] = useState('');
  const [exercises, setExercises] = useState([{ name: '', sets: '', reps: '', weight: '' }]);
  const navigate = useNavigate();

  const handleExerciseChange = (index, field, value) => {
    const newExercises = [...exercises];
    newExercises[index][field] = value;
    setExercises(newExercises);
  };

  const addExercise = () => {
    setExercises([...exercises, { name: '', sets: '', reps: '', weight: '' }]);
  };

  const removeExercise = (index) => {
    const newExercises = exercises.filter((_, i) => i !== index);
    setExercises(newExercises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/workouts`, {
        name: workoutName,
        type: 'gym',
        duration: parseInt(duration),
        exercises: exercises
      });
      navigate('/');  // Redirect to dashboard after successful submission
    } catch (err) {
      console.error('Error logging workout:', err);
    }
  };

  return (
    <FormContainer>
      <h2>Log Gym Workout</h2>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={workoutName}
          onChange={(e) => setWorkoutName(e.target.value)}
          placeholder="Workout Name"
          required
        />
        <Input
          type="number"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          placeholder="Duration (minutes)"
          required
        />
        {exercises.map((exercise, index) => (
          <ExerciseContainer key={index}>
            <Input
              type="text"
              value={exercise.name}
              onChange={(e) => handleExerciseChange(index, 'name', e.target.value)}
              placeholder="Exercise Name"
              required
            />
            <Input
              type="number"
              value={exercise.sets}
              onChange={(e) => handleExerciseChange(index, 'sets', e.target.value)}
              placeholder="Sets"
              required
            />
            <Input
              type="number"
              value={exercise.reps}
              onChange={(e) => handleExerciseChange(index, 'reps', e.target.value)}
              placeholder="Reps"
              required
            />
            <Input
              type="number"
              value={exercise.weight}
              onChange={(e) => handleExerciseChange(index, 'weight', e.target.value)}
              placeholder="Weight (lbs)"
              required
            />
            <RemoveButton type="button" onClick={() => removeExercise(index)}>Remove</RemoveButton>
          </ExerciseContainer>
        ))}
        <AddButton type="button" onClick={addExercise}>Add Exercise</AddButton>
        <SubmitButton type="submit">Log Workout</SubmitButton>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ExerciseContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  align-items: center;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const AddButton = styled(Button)`
  background-color: #4CAF50;
  &:hover {
    background-color: #45a049;
  }
`;

const RemoveButton = styled(Button)`
  background-color: #f44336;
  &:hover {
    background-color: #da190b;
  }
`;

const SubmitButton = styled(Button)`
  background-color: #4A25E1;
  &:hover {
    background-color: #3a1db1;
  }
`;

export default LogWorkoutGym;