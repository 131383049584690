import React from 'react';

const Jamiro = () => {
  const containerStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '40px 20px',
    fontFamily: "'Inter', sans-serif",
    color: '#888888',
    backgroundColor: '#000000',
    lineHeight: '1.4',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '24px',
    fontWeight: 'normal',
    color: '#888888',
  };

  const linkStyle = {
    color: '#ffffff',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const cardStyle = {
    backgroundColor: '#111111',
    borderRadius: '12px',
    padding: '20px',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'flex-start',
  };

  const iconPlaceholder = {
    width: '48px',
    height: '48px',
    backgroundColor: '#222222',
    borderRadius: '8px',
    marginRight: '16px',
    flexShrink: 0,
  };

  const cardContentStyle = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  };

  const cardTitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '4px',
  };

  const cardDescriptionStyle = {
    fontSize: '14px',
    color: '#888888',
    marginBottom: '0',
    maxWidth: '70%',
  };

  const buttonStyle = {
    padding: '8px 16px',
    backgroundColor: '#222222',
    color: '#ffffff',
    textDecoration: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: 'bold',
    alignSelf: 'flex-end',
    marginTop: 'auto',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>
        I'm <a href="https://www.instagram.com/mirocantcode/?hl=en" style={linkStyle}>Jamiro Ferguson</a>, I build, invest and actively create solutions for brands and entrepreneurs.
      </h1>

      <p style={headingStyle}>
        Currently I'm growing <span style={linkStyle}>MiroFitness</span>, a fitness platform focused on helping influencers and creators provide personalized, data-driven fitness experiences for their communities.
      </p>

      <p style={headingStyle}>
        I build software, consult with brands, and automate processes to enhance their growth, and I document it all publicly on <span style={linkStyle}>Youtube</span>. I also share my insights, templates and tools in <span style={linkStyle}>my free community</span> (every week).
      </p>

      {/* Community Section */}
      <div style={cardStyle}>
        <div style={iconPlaceholder}></div>
        <div style={cardContentStyle}>
          <h2 style={cardTitleStyle}>Join my community (free)</h2>
          <p style={cardDescriptionStyle}>Where I share my learnings, host online/offline events & more</p>
        </div>
        <a href="#" style={buttonStyle}>Join</a>
      </div>

      {/* MiroFitness Section */}
      <div style={cardStyle}>
        <div style={iconPlaceholder}></div>
        <div style={cardContentStyle}>
          <h2 style={cardTitleStyle}>MiroFitness</h2>
          <p style={cardDescriptionStyle}>Tap into the future of fitness tech and personal training.</p>
        </div>
        <a href="https://mirofitness.com" style={buttonStyle}>Learn more</a>
      </div>

      {/* CvAnalyst Section */}
      <div style={cardStyle}>
        <div style={iconPlaceholder}></div>
        <div style={cardContentStyle}>
          <h2 style={cardTitleStyle}>CvAnalyst</h2>
          <p style={cardDescriptionStyle}>Tailor your resumes in seconds, Land more interviews</p>
        </div>
        <a href="https://analyticpinnacle.thrivecart.com/cv-analyst/" style={buttonStyle}>Learn more</a>
      </div>
    </div>
  );
};

export default Jamiro;