import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import profilePic from '../images/Anonymous-Profile-pic.jpg';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #4A25E1;
  cursor: pointer;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  font-weight: 700; // Changed to bold
  transition: color 0.3s ease;

  &:hover {
    color: #4A25E1;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 1rem;
`;

const LoginButton = styled(Button)`
  background-color: #4A25E1;
  color: #FFF;
  font-weight: 700; // Changed to bold

  &:hover {
    background-color: #3a1db1;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(74, 37, 225, 0.2);
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProfilePic = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #4A25E1;
  cursor: pointer;
`;

const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const handleNavigation = (path) => () => navigate(path);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
    navigate('/');
  };

  return (
    <HeaderContainer>
      <Logo onClick={handleNavigation('/')}>MiroFitness</Logo>
      <Nav>
        <NavLink onClick={handleNavigation('/dashboard')}>Dashboard</NavLink>
        <NavLink onClick={handleNavigation('/profile')}>Profile</NavLink>
        <NavLink onClick={handleNavigation('/exercises')}>Exercises</NavLink>
        <NavLink onClick={handleNavigation('/workouts')}>Workouts</NavLink>
        <NavLink onClick={handleNavigation('/workout-planner')}>Workout</NavLink>
        <NavLink onClick={handleNavigation('/diet-planner')}>Diet</NavLink>
        <NavLink onClick={handleNavigation('/nutrition-calculator')}>BMI & Nutrition</NavLink>
        {user ? (
          <ProfileContainer>
            <ProfilePic src={profilePic} alt="Profile" onClick={handleNavigation('/profile')} />
            <Button onClick={handleLogout}>Logout</Button>
          </ProfileContainer>
        ) : (
          <LoginButton onClick={handleNavigation('/signin')}>Login</LoginButton>
        )}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;